import router from '@/router'
import {notification} from 'ant-design-vue'


import * as jwt from '@/services/jwt'

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    telegram_name: '',
    authorized: false,
    loading: false,
    notification: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    UPDATE_NOTIFICATION(state, payload) {
      return state.notification = state.notification.filter(obj => {
        return obj.id !== payload.id
      });
    },
  },
  actions: {
    CONFIRMATION({commit, dispatch, rootState}) {
      jwt.confirmation().then(data => {
        if (data.status) {
          dispatch('LOAD_CURRENT_ACCOUNT')
        } else {
          setTimeout(() => {
            dispatch('CONFIRMATION')
          }, 2500)
        }
      })
    },
    LOGIN({commit, dispatch, rootState}, {payload}) {
      const {email, password} = payload
      commit('SET_STATE', {
        loading: true,
      })

      jwt.login(email, password).then(success => {
        commit('SET_STATE', {
          loading: false,
        })
        if (success) {
          if (success.tfa) {
            notification.error({
              message: 'Logged In',
              description: 'You have need confirmed sign in!',
            })
            dispatch('CONFIRMATION')
          } else {
            dispatch('LOAD_CURRENT_ACCOUNT')
            notification.success({
              message: 'Logged In',
              description: 'You have successfully logged in!',
            })
          }
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({commit, dispatch, rootState}) {
      commit('SET_STATE', {
        loading: true,
      })

      jwt.currentAccount().then(response => {
        if (response) {
          commit('SET_STATE', response.user)
        } else {
          dispatch('LOGOUT')
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    LOGOUT({commit, rootState}) {
      jwt.logout().then(() => {
        commit('SET_STATE', {
          id: '',
          name: '',
          role: '',
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
          notification: [],
          telegram_name: '',
        })
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: state => state,
  },
}
