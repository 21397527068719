<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="https://visualbuilder.cloud"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo"
      >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#4b7cf3" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4627.3,2848.3c-101.5-42.1-99.5-36.4-392.4-1602.3c-157-827-287.1-1506.5-291-1512.3c-5.7-3.8-59.3,45.9-120.6,111c-451.8,482.4-505.4,532.2-580,532.2c-40.2,0-90-13.4-112.9-28.7c-23-17.2-137.8-189.5-254.6-382.8l-212.5-354.1H1445.7H230.2l-65.1-65.1C123-496,100-538.1,100-568.7c0-67,47.9-158.9,93.8-183.8c24.9-11.5,482.4-19.2,1338.1-19.2c1257.7,0,1301.7,1.9,1347.7,36.4c26.8,21,122.5,164.6,214.4,317.8c91.9,153.1,174.2,277.6,183.8,273.7c7.7-3.8,166.5-166.5,352.2-363.7c356.1-377.1,398.2-407.7,501.5-356c28.7,13.4,67,45.9,86.1,72.7c23,32.5,93.8,371.4,231.6,1104.5c107.2,581.9,201,1050.9,208.7,1043.3c5.7-7.7,116.8-878.7,245-1937.2c187.6-1525.7,243.1-1933.4,268-1971.7c70.8-101.5,199.1-118.7,285.2-38.3c26.8,24.9,88.1,187.6,176.1,463.3c76.6,235.5,204.8,639.4,289.1,901.6l153.1,472.8h271.8c208.7,0,283.3,5.7,312,28.7c23,15.3,134,143.6,248.8,287.1C7022-295,7119.6-178.2,7125.4-178.2c5.7-1.9,95.7-114.9,201-252.7c105.3-137.8,216.3-269.9,245-294.8l55.5-46h1073.9c989.7,0,1079.7,3.8,1116,34.5c103.4,82.3,111,191.4,19.1,283.3l-65.1,65.1h-997.3h-995.4l-262.2,335c-143.6,183.8-275.7,344.6-294.8,354.1c-45.9,24.9-145.5,24.9-189.5,0c-19.1-9.6-153.1-162.7-296.7-342.6l-262.2-325.4l-313.9-5.8c-273.7-5.7-315.9-9.6-352.2-42.1c-24.9-23-80.4-162.7-153.1-392.4c-63.2-195.3-141.6-442.2-176.1-547.5c-32.5-105.3-63.2-181.9-67-172.3c-3.8,11.5-55.5,411.6-112.9,890.1c-350.3,2842.7-413.5,3344.2-430.7,3390.2C4830.2,2838.7,4717.2,2884.6,4627.3,2848.3z"></path></g></svg>
        <strong class="mr-2">{{ settings.logo }}</strong>
      </a>
      <br />
      <p class="mb-0">
        Copyright © {{ new Date().getFullYear() }} |
        <a href="#" rel="noopener noreferrer">
          Impulse Team
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
