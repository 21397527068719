<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <i class="fe fe-database" :class="$style.icon" />
      <span class="d-none d-xl-inline">{{ $t('topBar.projectManagement') }}</span>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item-group title="Domains">
          <a-menu-item v-for="domain in domains" :key="domain.id">
            <router-link :to="`/domain/${domain.id}`" href="javascript: void(0);"><i class="fa fa-globe"></i> {{ domain.name }}</router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  inject: ['apiClient'],
  data() {
    return {
      domains: [],
    }
  },
  created() {
    this.apiClient.get(`/domains/projects/`)
      .then(response => {
        this.domains = response.data;
      })
  },

}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
