export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    category: true,
    title: 'Dashboards',
    key: 'q7r3hr',
  },
  {
    title: 'Main',
    key: '__dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  {
    title: 'Statistics',
    key: '__statistics',
    url: '/statistics',
    icon: 'icmn-stats-dots',
  },
  {
    title: 'Promo',
    key: '__promos',
    url: '/promos',
    icon: 'fa fa-magic',
  },
  {
    title: 'Users',
    key: '__users',
    url: '/users',
    icon: 'fa fa-users',
  },
  {
    title: 'Logs',
    key: '__logs',
    url: '/logs',
    icon: 'fa fa-binoculars',
  },
  {
    title: 'Support',
    key: '__support',
    url: '/support',
    icon: 'fa fa-question',
  },
  {
    title: 'Deposits',
    key: '__deposits',
    url: '/deposits',
    icon: 'fa fa-btc',
  },
  {
    title: 'Domains',
    key: '__domains',
    url: '/domains',
    icon: 'icmn-database',
  },
  {
    title: 'Ico',
    key: '__ico',
    url: '/icos',
    icon: 'icmn-coin-dollar',
  },
  {
    title: 'P2P',
    key: '__p2p',
    url: '/p2p',
    icon: 'icmn-connection',
  },
  {
    title: 'News',
    key: '__news',
    url: '/news',
    icon: 'icmn-ticket',
  },
  // VB:REPLACE-END:MENU-CONFIG
]
