import {createStore, createLogger} from 'vuex'
import user from './user'
import settings from './settings'
import support from './support'
import axios from 'axios'

export default createStore({
  modules: {
    user,
    settings,
    support,
  },
  state: {
    currencies: [],
    tickers: {},
    chartData: [],
    breadcrumb: '',
  },
  mutations: {
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    /**
     * Установить навигацию
     */
    SET_BREADCRUMB(state, status) {
      state.breadcrumb = status;
    },
    UPDATE_TICKER: (state, payload) => {
      const tick = state.tickers[payload.symbol]
      payload.pchg = tick ? (payload.price > tick.price ? 1 : -1) : 1
      state.tickers[payload.symbol] = payload;
    },
  },
  actions: {
    fetchCurrencies({commit, getters}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/coins/list')
          .then(data => {
            const currencies =  data.data.data;
            commit('SET_CURRENCIES',currencies)
            resolve(currencies)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
  },
  getters: {
    getSymbolById: state => (symbol) => {
      return state.currencies.find(s => s.name === symbol);
    },
    getTickerById: state => (symbol) => {
      return state.tickers[symbol]
    },
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [] //createLogger()
    : [],
})
