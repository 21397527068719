import apiClient from '@/services/axios'
export default {
  namespaced: true,
  state: {
    departments: {
      1: 'Deposits',
      2: 'Withdrawal',
      3: 'Trading',
      4: 'Account management',
      5: 'Other',
      6: 'Live Chat',
      7: 'P2P Chat',
    },
    users: [],
    tickets: [],
    messages: [],
  },
  mutations: {
    CLEAR(state) {
      state.messages = [];
      state.users = [];
      state.tickets = [];
    },
    SET_USERS(state, data) {
      state.users = data;
    },
    SET_TICKETS(state, data) {
      state.tickets = data.filter(obj => {
        if(obj.department === "7"){
          obj.title = `P2P order ${obj.title}`
        }
        obj.department = state.departments[obj.department];
        return obj
      });
    },
    SET_MESSAGE(state, messages) {
      state.messages = messages;
    },
    UPDATE_MESSAGE(state, data) {
      switch(data.action) {
        case 'push':
          return state.messages.push(data.message);
        case 'delete':
          return state.messages = state.messages.filter(obj => {
            return obj.id !== data.id
          });
        case 'read':
          return state.messages = state.messages.filter(obj => {
            obj.user_read = 1
            return obj
          });
        case 'clear':
          return state.messages = [];
      }
      state.messages.forEach(ele => {
        // //Чел, который отправил это
        // if (ele.message.to_user == data.to_user && ele.message.from_user == data.from_user) {
        //   ele.message.message = data.message;
        //   ele.time = data.time;
        //   //Если это текущий чат, добавлять отметку о непрочитанности не нужно.
        //   if (data.chatOfNow) return
        //   //Увеличить количество непрочитанных сообщений
        //   if (!ele.unread) {
        //     ele.unread = unread + 1;
        //   } else {
        //     ele.unread += 1;
        //   }
        // }
      });
    },
    UPDATE_TICKET(state, data) {
      switch(data.action) {
        case 'push':
          return state.tickets.push(data.ticket);
        case 'close':
          state.tickets = state.tickets.filter(obj => {
            if(obj.id === data.ticket.id) obj.status = (obj.status == '1') ? 0 : 1;
            return obj
          });
          break;
        case 'read':
          let read = 0;
          state.tickets = state.tickets.filter(obj => {
            if(obj.id === data.ticket.id) read = obj.unread, obj.unread = 0;
            return obj
          });
          state.users = state.users.filter(obj => {
            if(obj.user === data.ticket.user) obj.unread -= read;
            return obj
          });
          break;
        case 'mark':
          state.tickets = state.tickets.filter(obj => {
            if(obj.id === data.ticket.id) obj.mark = (obj.mark == '1') ? 0 : 1;
            return obj
          });
          break;
        case 'delete':
          state.messages = [];
          return state.tickets = state.tickets.filter(obj => {
            return obj.id !== data.ticket.id
          });
      }
    },
  },
  actions: {
    action({
             commit
           }, payload) {
      apiClient.get(`/support/edit/${payload.id}/${payload.type}`)
        .then(response => {
          commit('UPDATE_TICKET', {
            action: payload.type,
            ticket: response.data,
          })
        })
    },
  },
  getters: {
    users: state => {
      return state.users.sort(function (a, b) {
        return b.user - a.user;
      });
    },
    tickets: state => {
      state.tickets = state.tickets.sort(function (a, b) {
        return b.mark - a.mark || b.unread - a.unread;
      });
      // state.tickets =  state.tickets.sort(function (a, b) {
      //   return b.unread - a.unread;
      // });
      return state.tickets;
    },
    messages: state => state.messages,
  },
}
