<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="notifications.length">
        <i class="fe fe-bell" :class="$style.icon" />
      </a-badge>
    </div>
    <template #overlay>
      <div class="card vb__utils__shadow width-350">
        <div class="card-body p-0">
          <Notifications :list="notifications" />
        </div>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import Notifications from '@/components/Notifications'

export default {
  components: {
    Notifications,
  },
  setup() {
    const store = useStore()
    const notifications = computed(() => store.getters['user/user'].notification)


    return {
      notifications,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
