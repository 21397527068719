import axios from 'axios'
import store from 'store'
import NProgress from 'nprogress'
import {notification} from 'ant-design-vue'

const apiClient = axios.create({
  baseURL: '/api',
  headers: {'Content-Type': 'application/form-data'},
  transformRequest: [function (data, headers) {
    // Do whatever you want to transform the data
    let formData = new FormData();
    for (let key in data) {
       formData.append(key, data[key])
    }
    return formData;
  }],
})

apiClient.interceptors.request.use(request => {
  NProgress.start()
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(data => {
  NProgress.done()
  if (process.env.NODE_ENV === 'development')
    console.log(data.data)
  const {status, message} = data.data;
  if (status)
    return data.data;
  else if (!status && message) {
    notification.error({
      message: message,
    })
    return data.data;
  } else
    return false
}, error => {
  //if(error.response.status == 401)
  //window.location.reload(true);
  // Errors handling
  console.log(error.response.data)
  return error;
})

export default apiClient;

