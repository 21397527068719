import {createRouter, createWebHashHistory} from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        {
          path: '/dashboard',
          meta: {title: 'Dashboard'},
          component: () => import('./views/dashboard'),
        },
        {
          path: '/statistics',
          meta: {title: 'Statistics'},
          component: () => import('./views/dashboard/statistics'),
        },
        {
          path: '/promos',
          meta: {title: 'Promos'},
          component: () => import('./views/dashboard/promos'),
        },
        {
          path: '/users',
          meta: {title: 'Users list'},
          component: () => import('./views/dashboard/users/users'),
        },
        {
          path: '/user/:id',
          meta: {title: 'User'},
          component: () => import('./views/dashboard/users/user'),
        },
        {
          path: '/logs',
          meta: {title: 'Live logs'},
          component: () => import('./views/dashboard/logs'),
        },
        {
          path: '/support',
          meta: {title: 'Support system'},
          component: () => import('./views/dashboard/support'),
        },
        {
          path: '/templates',
          meta: {title: 'Templates system'},
          component: () => import('./views/dashboard/templates'),
        },
        {
          path: '/news',
          meta: {title: 'News system'},
          component: () => import('./views/dashboard/news/index'),
        },
        {
          path: '/news/add',
          meta: {title: 'News system'},
          component: () => import('./views/dashboard/news/add'),
        },
        {
          path: '/news/:id',
          meta: {title: 'News system'},
          component: () => import('./views/dashboard/news/edit'),
        },
        {
          path: '/p2p',
          meta: {title: 'P2P system'},
          component: () => import('./views/dashboard/p2p/index'),
        },
        {
          path: '/p2p/:id',
          meta: {title: 'P2P system'},
          component: () => import('./views/dashboard/p2p/order'),
        },
        {
          path: '/icos',
          meta: {title: 'Ico system'},
          component: () => import('./views/dashboard/icos/icos'),
        },
        {
          path: '/ico/:id',
          meta: {title: 'Ico Edit'},
          component: () => import('./views/dashboard/icos/ico'),
        },
        {
          path: '/ico/add',
          meta: {title: 'Ico Add'},
          component: () => import('./views/dashboard/icos/add'),
        },
        {
          path: '/deposits',
          meta: {title: 'Deposits'},
          component: () => import('./views/dashboard/deposits'),
        },
        {
          path: '/domains',
          meta: {title: 'My domains'},
          component: () => import('./views/dashboard/domains/domains'),
        },
        {
          path: '/domain/:id',
          meta: {title: 'Domain'},
          component: () => import('./views/dashboard/domains/domain'),
        },
        {
          path: '/settings',
          meta: {title: 'Settings'},
          component: () => import('./views/dashboard/settings'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: {name: 'route404'},
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: {redirect: to.fullPath},
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
