import {createApp, provide} from 'vue'
/*Modules*/
import Antd from 'ant-design-vue'
import VueSocketIOExt from 'vue-socket.io-extended';
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import PerfectScrollbar from 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.esm'
import App from './App.vue'
import './registerServiceWorker'
/*Components*/
import router from './router'
import store from './store'
import {i18n} from './localization'
/*services*/
import apiClient from '@/services/axios'
import socket from '@/services/socket-client';

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: "https://d0629e7b37904b8580d9360f535ed99c@o930829.ingest.sentry.io/5879512",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "impulse-squad.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
app.use(store)
  .use(router)
  .use(VueSocketIOExt, socket)
  .use(i18n)
  .use(Antd)
  .use(PerfectScrollbar)
  .provide('apiClient', apiClient)
  .mount('#app')
if (process.env.NODE_ENV === 'development') {
  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor
  }
}
