<template>
  <div>
    <ul class="list-unstyled mb-0">
      <li :class="$style.item" v-if="list.length == 0">
        <div class="mr-2">
          <div>Empty notifications</div>
        </div>
      </li>
      <li :class="$style.item" v-for="noty in list" :key="noty.id" v-else>
        <router-link :to="noty.link" :class="$style.itemLink">
          <div :class="$style.itemDecline" @click="read(noty.id)">
            <i class="fa fa-remove"></i>
          </div>
          <div :class="$style.itemPic">
            <i class="fe fe-mail" />
          </div>
          <div class="mr-2">
            <div>{{ noty.msg }}</div>
            <div class="text-muted">{{ noty.date_create }}</div>
          </div>
          <div :class="$style.itemAction">
            <span />
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  inject: ['apiClient'],
  props:['list'],
  methods:{
    read(message){
      this.apiClient.post('/users/current/read',{message:message}).then(data => {
        if (data.status) {
          this.$store.commit('user/UPDATE_NOTIFICATION', {id: message});
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import 'style.module';
</style>
